import { useMutation, useQuery } from '@apollo/client';
import { gql } from 'optimus/hooks/graphql';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { createContext, useContext, useState, useEffect } from 'react';
import LoadingPage from 'my/pages/LoadingPage';
import useUid from 'my/lib/UseUid';

const AuthContext = createContext();

const GET_IDENTITY_QUERY = gql`
  query GetIdentity($uid: ID!) {
    identity(uid: $uid) {
      loggedIn
      mungedEmails {
        key
        value
      }
      mungedPhones {
        key
        value
      }
      contact {
        id
        accountId
        firstName
        lastName
        email
        phonePrimary
        phoneSecondary
      }
    }
    advisor: publicAdvisor(uid: $uid) {
      id
      eid
      name
      email
      phone
      imageUrl
      title
      bio
      profileImageUrl
      backgroundImageUrl
    }
    referralProgram: publicReferralProgram(uid: $uid) {
      id
      formTitle
      formTagline
      formButton
      ctaButton
      optionTitle
      optionDescription
      bannerButton
      bannerContent
    }
  }
`;
const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout(input: {}) {
      success
    }
  }
`;

function useAuthProvider() {
  const uid = useUid();
  const [identity, setIdentity] = useState(null);
  const [advisor, setAdvisor] = useState(null);
  const [referralProgram, setReferralProgram] = useState(null);
  const {
    called,
    loading,
    data,
    refetch: getIdentity,
  } = useQuery(GET_IDENTITY_QUERY, {
    variables: { uid: uid },
  });
  const [logout] = useMutation(LOGOUT_MUTATION);
  useEffect(() => {
    if (data) {
      setIdentity((_) => ({ ...data?.identity }));
      setAdvisor((_) => ({ ...data?.advisor }));
      setReferralProgram((_) => ({ ...data?.referralProgram }));
    } else {
      setIdentity((_) => null);
      setAdvisor((_) => null);
      setReferralProgram((_) => null);
    }
  }, [data]);

  const loggedIn = identity?.loggedIn || false;
  return {
    loading: called && loading,
    uid,
    loggedIn,
    identity,
    advisor,
    referralProgram,
    async verifyLogin() {
      await getIdentity({ variables: { uid: uid } });
    },
    async logout() {
      await logout();
      setIdentity(null);
      return true;
    },
  };
}

export function AuthProvider() {
  const auth = useAuthProvider();

  return (
    <AuthContext.Provider value={auth}>
      {auth.loading ? <LoadingPage /> : <Outlet />}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth({ children }) {
  const uid = useUid();
  const { loggedIn, loading } = useAuth();
  const { pathname } = useLocation();
  if (loading) {
    return <LoadingPage />;
  }

  const authenticated = loggedIn || gon?.user?.admin;
  const to = uid ? `/p/${uid}/authenticate` : '/';
  return authenticated ? (
    children
  ) : (
    <Navigate
      to={{ pathname: to, search: `?redirect=${encodeURIComponent(pathname)}` }}
    />
  );
}

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import GraphQLJSON, { GraphQLJSONObject } from 'graphql-type-json';

function createClient() {
  const cache = new InMemoryCache();

  const csrfToken = document
    .querySelector('meta[name=csrf-token]')
    .getAttribute('content');
  const options = {
    uri: `${gon.base_path}/graphql`,
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  };

  const link = new HttpLink(options);

  const client = new ApolloClient({
    link,
    cache,
    resolvers: {
      JSON: GraphQLJSON,
      JSONObject: GraphQLJSONObject,
    },
  });

  return client;
}

const client = createClient();
export default client;

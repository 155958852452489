import { useParams } from 'react-router-dom';

/***
 * Return the uid of the lead if its available via the path
 * Otherwise null or undefined
 */
export default function useUid() {
  const params = useParams();
  return params?.uid;
}

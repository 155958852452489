import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import ReactModal from 'react-modal';
import '../shared/styles/portal/index.scss';
import './styles.scss';
import App from './App';

Sentry.init({
  enabled: window?.gon?.environment === 'production',
  dsn: 'https://370de9c67acb4beaf703d1a81ad130d0@o507073.ingest.sentry.io/4506826070032384',
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  autoSessionTracking: true,
  tracesSampleRate: 0.0,
  environment: window?.gon?.environment || 'development',
  sampleRate: window?.gon?.environment === 'development' ? 0 : 1,
  initialScope: {
    user: { id: window?.gon?.user?.id, email: window?.gon?.user?.email },
  },

  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
});
Sentry.setUser({ id: window?.gon?.user?.id, email: window?.gon?.user?.email });

ReactModal.setAppElement('#app');

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

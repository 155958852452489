import {
  DocumentNode,
  NoInfer,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { useEffect, useState } from 'react';

export { useMutation, useQuery, gql } from '@apollo/client';

export function useQueryData<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<
    NoInfer<TData>,
    NoInfer<TVariables>
  > = Object.create(null)
): QueryResult<TData, TVariables> {
  const [data, setData] = useState<TData | null>(null);
  const response = useQuery<TData, TVariables>(query, options);
  useEffect(() => {
    if (response.data) {
      setData((_) => response.data);
    }
  }, [response.data]);
  return { ...response, data };
}

// export function useQuery<
//   TData = any,
//   TVariables extends OperationVariables = OperationVariables,
// >(
//   query: DocumentNode | TypedDocumentNode<TData, TVariables>,
//   options: QueryHookOptions<
//     NoInfer<TData>,
//     NoInfer<TVariables>
//   > = Object.create(null)
// ): QueryResult<TData, TVariables> {
//   return wrapHook(
//     "useQuery",
//     _useQuery,
//     useApolloClient(options && options.client)
//   )(query, options);
// }

import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'optimus/hooks/graphql';
import useUid from 'my/lib/UseUid';
import { useParams } from 'react-router-dom';

const MUTATION_CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      activity {
        id
      }
    }
  }
`;

export default function PageActivityTracker({ activityId, type, children }) {
  const uid = useUid();
  const params = useParams();
  const quoteId = params?.proposalId || params?.quoteId;
  const artifactId = params?.documentId || params?.artifactId;
  const [createActivity] = useMutation(MUTATION_CREATE_ACTIVITY);
  useEffect(() => {
    if (uid && gon.measureable) {
      createActivity({
        variables: {
          input: {
            uid,
            activityId,
            type,
            quoteId,
            artifactId,
            url: window.location.href,
          },
        },
      });
    }
  }, [activityId, type, quoteId, artifactId]);
  return <React.Fragment>{children}</React.Fragment>;
}
